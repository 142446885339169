import axios from 'axios';

import { getBaseUrl, getAPIVersion, getSecretKey } from 'config';

const version = getAPIVersion(false);
const path = `/api/Users/`;
const sk = getSecretKey();

axios.defaults.headers.post['Content-Type'] = 'application/json';

export async function addUser(context, data) {
  data.secretKey = sk;
  const response = await fetch(getBaseUrl() + path + 'adduser', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify(data),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export async function deleteUser(context, userId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'deleteuser',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      id: userId,
    },
  });
  const response = await fetch(getBaseUrl() + path + 'deleteuser', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({}),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}

export function getUser(token, id, orgId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getuser',
    headers: { Authorization: `Bearer ${token}` },
    data: {
      id,
      orgId,
    },
  });
}

export function getUserByToken(token) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getuserbytoken',
    headers: { Authorization: `Bearer ${token}` },
    data: {},
  });
}

export function getUsers(context, orgId) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getusers',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      orgId: orgId,
    },
  });
}

export function updateUserRoles(context, id, roleIds) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'updateuserroles',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      id,
      roleIds,
    },
  });
}

export function updateUser(context, data) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'updateuser',
    headers: { Authorization: `Bearer ${context.token}` },
    data: data,
  });
}

export function setUserParks(
  context,
  orgId,
  userId,
  parkIds,
  requestingUserId
) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'setuserparks',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      orgId,
      userId,
      parkIds,
      requestingUserId,
    },
  });
}

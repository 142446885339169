import * as React from 'react';
import { useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useCookies } from "react-cookie";
import { getOrganizations } from "services/OrgsService";
import Organization from "models/Organization";
import { addUser, deleteUser, updateUser, setUserParks, updateUserRoles } from '../../services/Users.Service';
import AuthContext from '../../context/Auth/auth';
import { useForm } from "react-hook-form";
import { FormInputText } from "components/form/FormInputText";
import { FormInputSelect } from "components/form/FormInputSelect";
import { FormInputMultipleSelect } from "components/form/FormInputMultipleSelect";
import { FormInputMultipleAutocomplete } from "components/form/FormInputMultipleAutocomplete";
import {
    CircularProgress,
  } from '@mui/material';

import FormLoader from "./loaders/FormLoader"

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import ConfirmationDeleteUser from "helpers/ConfirmationDeleteUser";

import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';



interface OrganizationOption {
    label: string;
    id: number;
}

interface RoleOption {
    label: string;
    id: number;
}

interface UserFormProps {
    orgId: number;
    userId: number;
    toggleDrawer: (newOpen: boolean) => void;
    refreshUsers: () => void;
    selectedUser: any;
    parkOptions: any;
}


interface IFormInput {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    organization: OrganizationOption;
    roles: any[],
    parks: any[]
}

const defaultValues: IFormInput = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    organization: { id: 0, label: '' },
    roles: [],
    parks: []
};

const AllRoles = [
    { id: 1, label: 'TraKidAdmin' },
    { id: 2, label: 'TraKidCs' },
    { id: 3, label: 'OrgAdmin' },
    { id: 4, label: 'ParkAdmin' },
    { id: 5, label: 'SecurityAdmin' },
    { id: 6, label: 'StaffApp' },
    { id: 7, label: 'Dispatch' },
    { id: 8, label: 'TrackerView' },
    { id: 9, label: 'DevTools' },
    { id: 10, label: 'Devices' },
    { id: 11, label: 'Reports' },
    { id: 12, label: 'EditScavenger' },
    { id: 13, label: 'ToggleScavenger' },
    { id: 14, label: 'ViewScavenger' },
    { id: 15, label: 'Parent' },
    { id: 16, label: 'InitialAccount' },
    { id: 17, label: 'Guardian' },
];



const UserForm: React.FC<UserFormProps> = ({ orgId, userId, selectedUser, toggleDrawer, parkOptions, refreshUsers }) => {
    const [cookies] = useCookies(["token"]);
    const [organizations, setOrganizations] = useState<OrganizationOption[]>([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoadig] = useState(true);


    const authContext = useContext(AuthContext);

    const schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        password: !selectedUser ? Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required') : Yup.string(),
        organization: Yup.object<OrganizationOption | null>()
            .nullable()
            .shape({
                label: Yup.string().required('Organization label is required'),
                id: Yup.number().required('Organization ID is required'),
            })
            .nullable()
            .required('Organization is required')
            .test('is-valid', 'Invalid organization', (value) => {
                if (value === null) return true;
                return (value as OrganizationOption).id > 0;
            })
    });



    const { handleSubmit, control, setValue, reset, formState: { errors } } = useForm({
        mode: "onBlur",
        resolver: yupResolver(schema),
        defaultValues: defaultValues,
        shouldFocusError: true,
        criteriaMode: "all",
        reValidateMode: "onChange"
    });


    const onSubmit = (data: any) => callSubmit(data);
    const callSubmit = (data: IFormInput) => {
        if (!selectedUser) {
            addUser(
                authContext,
                {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    userName: data.email,
                    email: data.email,
                    password: data.password,
                    orgId: data.organization ? data.organization.id : null
                }
            ).then(_data => {
                toggleDrawer(false)
                refreshUsers();
            });
        } else {
            const selectedParks = data.parks.map(item => item.id);
            const selectedRoles = data.roles.map(item => item.id);

            Promise.all([
                updateUser(authContext, {
                    id: selectedUser.Id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    userName: data.email,
                    email: data.email
                }),
                setUserParks(authContext,
                    data.organization ? data.organization.id : null,
                    selectedUser.Id,
                    selectedParks,
                    //state.userId
                ),
                updateUserRoles(authContext,
                    selectedUser.Id,
                    selectedRoles,
                    //state.userId
                )
            ]).then(([updateUserRolesData, setUserParksData, setUserRolesData]) => {
                toggleDrawer(false);
                refreshUsers();
            }).catch(error => {
                toggleDrawer(false);
                refreshUsers();
                console.error('Error handling user actions:', error);
            });
        }
    };


    const handleUserSelected = (organizationList: any): void => {
        if (selectedUser) {
            const selectedOrg: OrganizationOption =
                organizationList.find((org: any) => org.id === selectedUser.OrgId) || { id: 0, label: 'Unknown Organization' };
            const selectedParks = parkOptions.filter((park: any) => selectedUser.Parks.includes(park.id));
            const selectedRoles = AllRoles.filter((role: any) => selectedUser.UserRoles.includes(role.id));

            defaultValues.firstName = selectedUser.FirstName;
            defaultValues.lastName = selectedUser.LastName;
            defaultValues.email = selectedUser.UserName;
            defaultValues.organization = selectedOrg;
            defaultValues.parks = selectedParks;
            defaultValues.roles = selectedRoles;
            reset(defaultValues)
        } else {
            defaultValues.firstName = '';
            defaultValues.lastName = '';
            defaultValues.email = '';
            defaultValues.organization = { id: 0, label: '' };
            defaultValues.roles = [];
            defaultValues.parks = [];
            reset(defaultValues)
        }
    };

    const handleConfirmDialogClose = () => {
        setShowConfirmation(false);
    };

    const handleUserDelete = () => {
        deleteUser(authContext, selectedUser.Id).then((_data) => {
            setShowConfirmation(false);
            refreshUsers();
            toggleDrawer(false)
        })
    };
    const handleConfirmDialogOpen = (userId: number) => {
        setShowConfirmation(true);
    };

    useEffect(() => {
        if (cookies.token) {
            getOrganizations(cookies).then((data) => {
                if (typeof data === "undefined") {
                    return;
                } else {
                    const organizationList = data.map((org: Organization) => ({
                        label: org.Name,
                        id: org.OrgId,
                    }));
                    setOrganizations(organizationList);
                    handleUserSelected(organizationList);
                    setLoadig(false);
                }
            });
        }
    }, [cookies]);


    return (
        <>
            {loading ? (
                <FormLoader />
            ) : (
                <>
                    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between', position: 'relative' }}>
                        <Box
                            component="form"
                            sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 1, position: 'relative', top: 70 }}
                        >
                            <Typography
                                component="h1"
                                variant="h4"
                                sx={{ width: '100%', fontSize: 'clamp(1.5rem, 1.5vw, 1.5em)' }}
                            >
                                <Divider textAlign="left">
                                    {selectedUser ? "User Edit" : "User Registration"}
                                </Divider>
                            </Typography>
                            <Divider>
                                <Chip label="General Information" size="small" />
                            </Divider>
                            <FormControl>
                                <FormInputText name="firstName" control={control} errors={errors} label="First name" />
                            </FormControl>
                            <FormControl>
                                <FormInputText name="lastName" control={control} errors={errors} label="Last name" />
                            </FormControl>
                            <Divider>
                                <Chip label="Account Management" size="small" />
                            </Divider>

                            <FormControl>
                                <FormInputText name="email" control={control} errors={errors} label="Email" />
                            </FormControl>

                            {!selectedUser && (
                                <FormControl>
                                    <FormInputText name="password" control={control} errors={errors} label="Password" type='password' />
                                </FormControl>
                            )}


                            <Divider>
                                <Chip label="Authorization Level" size="small" />
                            </Divider>
                            <FormControl>
                                <FormInputSelect
                                    name="organization"
                                    control={control}
                                    errors={errors}
                                    label="Organization"
                                    options={organizations} />
                            </FormControl>
                            {selectedUser && (
                                <>
                                    <FormControl>
                                        <FormInputMultipleAutocomplete
                                            name="roles"
                                            control={control}
                                            errors={errors}
                                            placeholder="User roles"
                                            label="Roles"
                                            options={AllRoles} />

                                    </FormControl>
                                    <FormControl>
                                        <FormInputMultipleAutocomplete
                                            name="parks"
                                            control={control}
                                            errors={errors}
                                            placeholder="User Parks"
                                            label="Parks"
                                            options={parkOptions} />
                                    </FormControl>
                                    <Box
                                        sx={[
                                            {
                                                display: 'flex',
                                                flexDirection: { xs: 'column-reverse', sm: 'row' },
                                                alignItems: 'end',
                                                flexGrow: 1,
                                                gap: 1,
                                                pb: { xs: 12, sm: 0 },
                                                mt: { xs: 2, sm: 0 },
                                                justifyContent: 'flex-end'
                                            },

                                        ]}
                                    >
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        sx={{
                            position: 'relative',
                            top: 60,
                            p: 2,
                            gap: 1,
                            alignItems: 'center',
                            borderTop: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        <Button variant="text" onClick={() => toggleDrawer(false)}>
                            Cancel
                        </Button>
                        <Box
                            sx={[
                                {
                                    display: 'flex',
                                    flexDirection: { xs: 'column-reverse', sm: 'row' },
                                    alignItems: 'end',
                                    flexGrow: 1,
                                    gap: 1,
                                    pb: { xs: 12, sm: 0 },
                                    mt: { xs: 2, sm: 0 },
                                    justifyContent: 'flex-end'
                                },

                            ]}
                        >
                            {selectedUser && (
                                <Button variant="contained" color="error" onClick={() =>
                                    handleConfirmDialogOpen(selectedUser.Id)
                                }
                                >
                                    Delete
                                </Button>
                            )}

                            <Button variant="contained" onClick={handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                        </Box>
                    </Stack>
                    <ConfirmationDeleteUser
                        open={showConfirmation}
                        close={handleConfirmDialogClose}
                        confirm={handleUserDelete}
                        warning={true}
                    />
                </>
            )}
        </>
    );
}

export default UserForm;
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { IUser } from 'models/IUser';
import {
  getUsers,
  getUserByToken,
  updateUserRoles,
  setUserParks,
} from 'services/Users.Service';
import { IPark } from 'models/IPark';
import { fetchParksForOrg } from 'services/Parks.Service';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import MuiAvatar from '@mui/material/Avatar';
import MuiListItemAvatar from '@mui/material/ListItemAvatar';

import UserForm from "../common/UserForm"


const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: 28,
  height: 28,
  backgroundColor: (theme || theme).palette.background.paper,
  color: (theme || theme).palette.text.secondary,
  border: `1px solid ${(theme || theme).palette.divider}`,
}));

const ListItemAvatar = styled(MuiListItemAvatar)({
  minWidth: 0,
  marginRight: 12,
});



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function UserManagement() {
  const authContext = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [open, setOpen] = useState(false);

  const [userRowData, setUserRowData] = useState([]);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [allParksInOrg, setAllParksInOrg] = useState<IPark[]>();
  const [parkOptions, setParkOptions] = useState<any[]>();
  const [roleDidChange, setRoleDidChange] = useState(false);
  const [userParkDidChange, setUserParkDidChange] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const navigate = useNavigate();

  interface IChangeValues {
    value: string;
    checked: boolean;
  }

  const handleClickOpen = (user: IUser): void => {
    setSelectedUser(user);
    setSideBarOpen(true);
    //setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
    setSelectedUser(undefined);
  };

  const handleRoleChange = ({ value, checked }: IChangeValues): void => {
    setRoleDidChange(true);
    if (!selectedUser!.UserRoles)
      return

    let roles = [...selectedUser!.UserRoles];

    if (checked) {
      roles.push(parseInt(value));
    } else {
      roles = roles.filter(role => role !== parseInt(value));
    }

    setSelectedUser({
      ...selectedUser!,
      UserRoles: roles,
    });
  };

  const handleParkOptions = (values: any): void => {
    const options = values.map((item: any) => ({
      id: item.ParkId,
      label: item.Name
    }));
    setParkOptions(options);
  }

  const handleParkChange = ({ value, checked }: IChangeValues): void => {
    setUserParkDidChange(true);

    let parks = [...selectedUser!.Parks];

    // selectedUser!.Parks.forEach(park => parks.push(park.Id));

    if (checked) {
      parks.push(parseInt(value));
    } else {
      parks = parks.filter(id => id !== parseInt(value));
    }

    setSelectedUser({
      ...selectedUser!,
      Parks: parks,
    });
  };

  const handleUpdateUser = (): void => {
    setUpdating(true);

    if (roleDidChange) {
      updateUserRoles(authContext, selectedUser!.Id, selectedUser!.UserRoles)
        .then(response => {
          //TODO alert user
          console.log(response);
        })
        .catch(error => {
          //TODO alert user
          console.error(error);
        })
        .finally(() => {
          getUsers(authContext, state.orgId).then(response => {
            const users = response.data;

            setUserRowData(users);
            setUpdating(false);
            handleClose();
          });
        });
    }

    if (userParkDidChange) {
      setUserParks(
        authContext,
        state.orgId,
        selectedUser!.Id,
        selectedUser!.Parks,
        state.userId
      )
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          getUsers(authContext, state.orgId).then(response => {
            const users = response.data;

            setUserRowData(users);
            setUpdating(false);
            handleClose();
          });
        });
    }
  };

  useEffect(() => {
    setLoading(true);

    if (state.orgId !== null) {
      // Check for Authorization
      if (state.userRoles.indexOf(1) === -1) {
        navigate('/not-authorized');
      }
    }

    if (state === undefined) {
      getUserByToken(authContext.token).then(user => {
        const { UserRoles, EditableRoles, OrgId, Parks, Id } = JSON.parse(
          user.data
        );

        const parks = Parks.map((park: IPark) => park.Id);

        dispatch({ type: 'updateUserRoles', payload: UserRoles });
        dispatch({ type: 'updateEditRoles', payload: EditableRoles });
        dispatch({ type: 'updateUserOrgId', payload: OrgId });
        dispatch({ type: 'updateUserParkAccess', payload: parks });
        dispatch({ type: 'updateUserId', payload: Id });

        getUsers(authContext, OrgId).then(response => {
          const users = response.data;

          users.forEach((user: IUser) => {
            user.Parks = user.Parks.map((park: any) => park.Id);
          });

          users.forEach((user: any) => {
            user.UserRoles = user.Roles.map((user: any) => user.Id);
          });

          setUserRowData(users);
          setLoading(false);
        });

        fetchParksForOrg(authContext, OrgId).then(response => {
          setAllParksInOrg(response);
          handleParkOptions(response);
        });
      });
    } else {
      getUsers(authContext, state.orgId).then(response => {
        const users = response.data;
        users.forEach((user: IUser) => {
          user.Parks = user.Parks.map((park: any) => park.Id);
        });

        users.forEach((user: any) => {
          user.UserRoles = user.Roles.map((user: any) => user.Id);
        });

        setUserRowData(users);
        setLoading(false);
      });

      fetchParksForOrg(authContext, state.orgId).then(response => {
        setAllParksInOrg(response);
        handleParkOptions(response);
      });
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshUsersList = (): void => {
    setLoading(true);
    getUsers(authContext, state.orgId).then(response => {
      
      const users = response.data;
      users.forEach((user: IUser) => {
        user.Parks = user.Parks.map((park: any) => park.Id);
      });
      users.forEach((user: any) => {
        user.UserRoles = user.Roles.map((user: any) => user.Id);
      });
      setUserRowData(users);
      setLoading(false);
    });
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    setSelectedUser(undefined);
    setSideBarOpen(newOpen);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = userRowData.filter((user: IUser) => 
    user.FirstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.LastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    user.UserName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const drawerWidth = 340;
  return (
    <div>
      {loading ? (
        <CircularProgress style={{ alignSelf: 'center' }} />
      ) : (
        <>
          <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
            <AppBar
              position="static"
              color="default"
              elevation={0}
              sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                >
                  User Management
                </Typography>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onChange={handleSearchChange} 
                  />
                </Search>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  onClick={toggleDrawer(true)}
                >
                  <PersonAddAlt1Icon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Grid container spacing={8} columns={12} sx={{ my: 4 }}>
              <Grid display="flex" justifyContent="center" alignItems="center" size="grow" >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 1,
                    height: '100%',
                  }}
                >
                  <List>
                    {filteredUsers.map((user: IUser) => {
                      return (
                        <>
                          <ListItem key={`ListItem-${user.Id}`}
                            secondaryAction={
                              <IconButton
                                edge='end'
                                aria-label='edit user roles'
                                onClick={() => handleClickOpen(user)}
                              >
                                <EditIcon />
                              </IconButton>
                            }>
                            <ListItemAvatar>
                              <Avatar>
                                <AccountCircleIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={user.UserName}
                              secondary={`${user.LastName}, ${user.FirstName}`}
                            />
                          </ListItem>
                          <Divider key={`divider-${user.Id}`} variant="inset" component="li" />
                        </>
                      );
                    })}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}

      <Drawer
        open={sideBarOpen}
        onClose={() => toggleDrawer(false)}
        anchor={'right'}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth, boxSizing: 'border-box',
          },
        }}>
        <UserForm
          orgId={state.orgId}
          userId={state.userId}
          selectedUser={selectedUser}
          parkOptions={parkOptions}
          refreshUsers={refreshUsersList}
          toggleDrawer={toggleDrawer(false)}
        />
      </Drawer>
    </div>
  );
}

export default UserManagement;

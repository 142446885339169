import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import ParkMap from "../../pages/ExplorerQuest/cms/maps/ParkMap";
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import { IPark } from 'models/IPark';
import ParkForm, { ParkFormRef } from 'components/pages/ExplorerQuest/cms/forms/ParkForm';
import { boolean } from 'yup';

const Item = styled(Paper)(({ theme }) => ({
    padding: 12,
    marginTop: 5,
    height: '100%'
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface ParkComponentProps {
    park: IPark;
    organization: any;
    open: boolean;
    onEdit?: (selectedPark: IPark) => void;
    onParkDialog: (value: boolean) => void;
    onClickMap: (lat: number, lng: number) => void;
    onDelete:(selectedPark: IPark) => void;
    onSave:(selectedPark: IPark, updatedDataPark: IPark) => void;
}

export default function FullScreenDialog({ park, organization, onParkDialog, open, onClickMap, onDelete,  onSave}: ParkComponentProps) {
    const [loading, setLoading] = React.useState(false);
    const handleMapClick = (data: any) => {
        if (data) {
            onClickMap(data.lat, data.lng);
        }
    };

    const handleClose = () => {
        onParkDialog(false);
    };


    const parkFormRef = useRef<ParkFormRef>(null);

    const handleSave = () => {
        if (parkFormRef.current) {
            parkFormRef.current.submitForm();
        }
    };

    const handleDelete = () => {
        if (parkFormRef.current) {
            parkFormRef.current.showConfirm();
        }
    };

    const handleSaving = (value: boolean) => {
        setLoading(value);
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                scroll={'paper'}
            >
                <AppBar sx={{ position: 'relative' }} color='transparent'>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {park.Name}
                        </Typography>
                        <Stack direction="row" spacing={2}>
                            {park.ParkId != 0 && (
                                <Button variant="outlined" autoFocus color="error" onClick={handleDelete} startIcon={<DeleteIcon />}>
                                    Delete
                                </Button>
                            )}
                            <Button disabled={loading} variant="outlined" autoFocus color="primary" onClick={handleSave} endIcon={<SendIcon />}>
                                Save
                            </Button>
                        </Stack>
                    </Toolbar>
                </AppBar>
                <DialogContent >
                    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between', position: 'relative' }}>
                        <Box
                            sx={{ width: 'auto' }}
                            role="presentation"
                        >
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                                    <Item square>
                                        <ParkForm currentPark={park} 
                                        currentOrg={organization} 
                                        ref={parkFormRef} onDelete={onDelete} 
                                        onSave={onSave} handleSaving={handleSaving}/>
                                    </Item>
                                </Grid>

                                <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                                    <Item square>
                                        <ParkMap
                                            clickCallback={handleMapClick}
                                            disbledMarkerMove={false}
                                            mapCenter={{ lat: park.Latitude, lng: park.Longitude }}
                                            lat={park.Latitude}
                                            lng={park.Longitude}
                                            radius={0}
                                            color={'#fff'} existingLocations={[]} currentMarker={{ Latitude: park.Latitude, Longitude: park.Longitude, ZoneId: park.ParkId, Zoom: park.Zoom }} />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
import { IPoint } from 'models/IPoint';

import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';

// Paths to Default Images must be absolute.
import detailBgBlank from '../../../../../images/cms/activity/find-screen-blank.png';
import defaultImg from '../../../../../images/cms/activity/no-image-default.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${detailBgBlank}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      width: '320px',
    },
  },
  pointImage: {
    display: 'grid',
    maxWidth: '320px',
    marginTop: '74px',
  },
  image: {
    justifySelf: 'center',
    maxHeight: '155px',
    minHeight: '155px',
    maxWidth: '320px',
    borderRadius: '10px',
  },
  header: {
    textAlign: 'left',

    '& > h3': {
      color: '#000',
      padding: '0 10px',
    },
  },
  question: {
    textAlign: 'left',

    '& > p': {
      color: '#000',
      padding: '0 10px',
    },
  },
  answers: {
    flexGrow: 2,
    marginBottom: '112px',
    display: 'grid',
    paddingBottom: '10px',

    '& > div': {
      alignSelf: 'start',
      border: '2px solid',
      //borderColor: theme.palette.primary.main,
      borderRadius: '10px',
      //color: theme.palette.primary.main,
      fontWeight: 700,
      margin: '5px 5px',
      padding: '8px 0',
    },
  },
}));

interface IProps {
  waypoint: IPoint;
}
function DetailPreview(props: IProps) {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.root} >
        <Grid container alignItems="center"
          justifyContent="center">
          <Grid className={classes.pointImage} size={12}>
            {props.waypoint.Image !== undefined ? (
              <img
                src={props.waypoint.Image}
                alt='Point Imagery'
                className={classes.image}
              />
            ) : (
              <img
                src={defaultImg}
                alt='Default Imagery'
                className={classes.image}
              />
            )}
          </Grid>
          <Grid className={classes.header} size={12}>
            {props.waypoint.LongName !== '' ? (
              <h3>{props.waypoint.LongName}</h3>
            ) : (
              <h3>
                <em>Long Name Text Placeholder</em>
              </h3>
            )}
          </Grid>
          <Grid className={classes.question} size={12}>
            {props.waypoint.Description !== '' ? (
              <p>{props.waypoint.Description}</p>
            ) : (
              <p>
                <em>Description Text Placeholder</em>
              </p>
            )}
          </Grid>
          <Grid className={classes.answers} size={12}>
            {props.waypoint.ShortName ? (
              <div>I found the {props.waypoint.ShortName}</div>
            ) : (
              <div>I found the [Short Name]</div>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DetailPreview;
